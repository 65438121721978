import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { DEFAULT_FONTS } from '@shared/feature/themes';
import { ThemingService } from '../../core/theming.service';
import { ThemeModel } from '../../shared/models/theme.model';
import { FontPickerComponent } from '../font-picker/font-picker.component';
import { PalettePickerComponent } from '../palette-picker/palette-picker.component';
import { TextLegibilityComponent } from '../text-legibility/text-legibility.component';
import { ThemeEditorService } from '../theme-editor.service';
import { ThemeSettingsComponent } from '../theme-settings/theme-settings.component';

@Component({
  selector: 'lsf-themes-editor-wizard',
  templateUrl: './wizard.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatStepperModule,
    NgIf,
    PalettePickerComponent,
    NgTemplateOutlet,
    TextLegibilityComponent,
    FontPickerComponent,
    ThemeSettingsComponent,
    MatButtonModule,
    FormsModule,
  ],
})
export class WizardComponent implements OnInit, OnDestroy {
  showWizardActionButtons = true;
  editTheme = false;
  storedTheme: ThemeModel | undefined;
  uniqueFormId = `form-${Math.random().toString(16).slice(2)}`;

  @Input() creating = false;
  @Input() editProjectTheme = false;
  @Input() set theme(theme: ThemeModel) {
    // Prepare for (future) fontStyles, provided by in the DEFAULT_FONTS
    theme.typographyConfig = { ...DEFAULT_FONTS, ...theme.typographyConfig };
    this.themeEditorService.theme = theme;
    this.editTheme = true;
    this.themeEditorService.editTheme = this.editTheme;
    this._theme = theme;
    if (this.stepper) {
      this.stepper.selectedIndex = 0;
    }
    this.storedTheme = this.themingService.tryGetTheme(this.theme.id);
  }

  get theme() {
    return this._theme;
  }

  @ViewChild('wizardActionButtons') buttons: TemplateRef<unknown> = null!;
  @ViewChild('stepper') stepper?: MatStepper;
  private _theme: ThemeModel = null!;

  constructor(
    private themeEditorService: ThemeEditorService,
    private themingService: ThemingService,
    private matSnackBar: MatSnackBar,
    destroyRef: DestroyRef,
  ) {
    this.themingService.themeUpdate$.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => {
      this.stepper!.selectedIndex = 0;
    });
  }

  ngOnDestroy() {
    if (!this.creating) {
      this.themeEditorService.clear();
    }
  }

  ngOnInit() {
    this.themeEditorService.editTheme = this.editTheme;
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      if (this.editProjectTheme) {
        this.themingService.updateProjectTheme(this.theme);
      } else if (!this.storedTheme) {
        this.themeEditorService.addTheme();
        this.matSnackBar.open(`Theme '${this.themeEditorService.theme.name}' has been created.`);
      } else {
        this.themeEditorService.updateTheme();
        this.matSnackBar.open(`Theme '${this.theme.name}' has been updated.`);
      }
    }
  }
}
