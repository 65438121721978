import { Directive, HostBinding, inject, Input } from '@angular/core';
import { ThemeColor, ThemeColorService, ThemeMainColorType } from '@shared/feature/themes';
import tinycolor from 'tinycolor2';

export enum ThemeColorType {
  primary = ThemeMainColorType.primaryColor,
  accent = ThemeMainColorType.accentColor,
  warn = ThemeMainColorType.warnColor,
}

export type ColorSettings = {
  palette: ThemeColor;
  paletteKey: string; // One of palette keys (e.g. 500 or contrast-500)
};

function getPalette(palette: string) {
  if (palette === 'light' || palette === 'dark') {
    palette = 'grey';
  }

  return palette;
}

@Directive({
  selector: '[lsuDirectivesColor]',
  standalone: true,
})
export class ColorDirective<T extends ColorSettings> {
  className!: string | null;
  themeColorService = inject(ThemeColorService);
  @Input() set lsuDirectivesColor(settings: T | null) {
    this.className = settings && settings.palette !== ThemeColor.None ? ColorDirective.getClassName(settings) : null;
  }

  @HostBinding('class') get class() {
    return this.className;
  }

  static getOppositeClassName(settings: ColorSettings) {
    const palette = getPalette(settings.palette);
    const colorKey = settings.paletteKey;

    return `bg--${palette}-contrast-${settings.paletteKey} color--${palette}-${colorKey}`;
  }

  static getClassName(settings: ColorSettings) {
    const palette = getPalette(settings.palette);
    const colorKey = settings.paletteKey;

    const hex = ColorDirective.getComputedHexValue(settings);
    return `bg--${palette}-${settings.paletteKey} color--${palette}-contrast-${colorKey} ${
      tinycolor(hex).isLight() && !document.body.classList.contains('dark-theme') ? 'bg--is-light' : 'bg--is-dark'
    }`;
  }

  static getComputedHexValue(settings: ColorSettings) {
    const palette = getPalette(settings.palette);
    const colorKey = settings.paletteKey;
    return getComputedStyle(document.body).getPropertyValue(`--theme-${palette}-color-${colorKey}`);
  }
}
