import { LocationStrategy } from '@angular/common';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationError, Router } from '@angular/router';
import { DialogAlertComponent } from '@shared/ui/dialog';
import { isGuidRegExp, safeSessionStorage } from '@shared/util/code';
import { TranslationService } from '@shared/util/translations';
import { filter } from 'rxjs';
import 'zone.js';
import { LoadingIndicatorService } from '../loader/loading-indicator.service';
import { AppInsightsService } from './app-insights.service';
import { DesanErrorHandler } from './desan-error-handler';
import { getEnvironment } from './get-environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private dialogIsOpen = false;
  private lastMessage?: string;

  constructor(
    private document: Document,
    private injector: Injector,
    router: Router,
    locationStrategy: LocationStrategy,
  ) {
    router.events.pipe(filter((e) => e instanceof NavigationError)).subscribe((e) => {
      const navigationError = e as NavigationError;
      locationStrategy.replaceState('', '', navigationError.url, '');
    });
  }
  handleError(error: Error): void {
    console.error(`Angular zone: ${Zone.current.name}`);
    const translationsService = this.injector.get(TranslationService);
    const convertor = new DesanErrorHandler(error, translationsService);
    const alert = convertor.getAlertMessage();
    const zone: NgZone = this.injector.get(NgZone);
    const loadingService = this.injector.get(LoadingIndicatorService);
    const router = this.injector.get(Router);

    if (alert.unauthorized) {
      let projectGuid = this.document.location.pathname.split('/').find((x) => isGuidRegExp.test(x));
      projectGuid = projectGuid ? `/${projectGuid}` : '';
      safeSessionStorage.setItem('userRequestUrl', this.document.location.href.replace(this.document.baseURI, ''));
      void zone.run(() => router.navigateByUrl(`${projectGuid}/auth/login`));
      return;
    }

    if (alert.message === this.lastMessage || convertor.shouldIgnore()) {
      return;
    }
    this.lastMessage = alert.message;

    if (alert.isDeveloperError) {
      if (error.message === '_errors_general_title') {
        error.message = 'Api error see Seq for details';
      }
      AppInsightsService.logError(error, { itzhmwTitle: alert.title });
      if (['production', 'staging'].includes(getEnvironment(location))) {
        alert.title = translationsService.translateItem('_errors_general_title');
        alert.message = translationsService.translateItem('_errors_general_description');
      }
    } else {
      AppInsightsService.trackTraceWarn((alert.apiStatus ?? '') + alert.title, { detail: alert.message });
    }

    loadingService.clear();
    if (!this.dialogIsOpen && Object.keys(alert).length > 0) {
      this.dialogIsOpen = true;
      const matDialog: MatDialog = this.injector.get(MatDialog);
      void zone.run(async () => {
        await DialogAlertComponent.open(matDialog, { data: alert, maxWidth: '95vw', restoreFocus: true, hasBackdrop: true, disableClose: false });
        this.dialogIsOpen = false;
        this.lastMessage = undefined;
        if (alert.buttonEvent) {
          alert.buttonEvent!.call(this);
        }
      });
    }
  }
}
