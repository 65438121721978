import { Injectable } from '@angular/core';
import { CurrentUserClient, CurrentUserModel } from '@shared/data-access/common';
import { safeLocalStorage } from '@shared/util/code';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

export type ImpersonateUser =
  | {
      id: number;
      name: string;
      role: string;
    }
  | undefined;

@Injectable({ providedIn: 'root' })
export class ImpersonateService {
  impersonate$ = new BehaviorSubject<ImpersonateUser>(undefined);
  constructor(private currentUserClient: CurrentUserClient) {}
  async tryRestoreClientSideUser() {
    const obj = safeLocalStorage.getItem('impersonateUser');
    const user = await firstValueFrom(this.currentUserClient.getCurrentUser());
    if (obj) {
      safeLocalStorage.removeItem('impersonateUser'); // first remove. If things go wrong we not stay in a loop
      const localStorageUser: ImpersonateUser = JSON.parse(obj);
      if (localStorageUser?.id !== user.id) {
        // Different user in local storage than on the server, indicating unimpersonation
        return undefined;
      }
      return await this.impersonate(localStorageUser!.id);
    }
    return undefined;
  }

  async tryRestoreServerSideUser(userIdFromClaim: number) {
    const user = await firstValueFrom(this.currentUserClient.getCurrentUser());
    return userIdFromClaim !== user.id ? this._impersonate(user) : undefined;
  }

  async impersonate(id: number): Promise<ImpersonateUser> {
    const user = await firstValueFrom(this.currentUserClient.impersonateAndGetImpersonatedUser(id));
    return this._impersonate(user);
  }

  async undoImpersonate() {
    safeLocalStorage.removeItem('impersonateUser');
    await firstValueFrom(this.currentUserClient.undoImpersonate());
    this.impersonate$.next(undefined);
  }

  private _impersonate(user: CurrentUserModel) {
    const impersonateUser = this.createImpersonateUser(user);
    safeLocalStorage.setItem('impersonateUser', JSON.stringify(impersonateUser));
    this.impersonate$.next(impersonateUser);
    return impersonateUser;
  }

  private createImpersonateUser(user: CurrentUserModel) {
    return { id: user.id, name: user.userName, role: user.role };
  }
}
