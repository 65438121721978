/* eslint-disable @typescript-eslint/no-explicit-any */
// see https://javascriptweblog.wordpress.com/2011/08/08/fixing-the-javascript-typeof-operator/
import { isType } from '../';

// see https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
const toType = <T>(a: T) => ({}).toString.call(a).match(/([a-z]+)(:?\])/i)![1];
const isObject = (obj: unknown): obj is object => toType(obj) === 'Object';
const isArray = (obj: unknown): obj is object => toType(obj) === 'Array';

function copyProperty<T>(source: T, target: T, property: keyof T) {
  const sourceProperty = source[property];
  target[property] =
    isObject(sourceProperty) && isObject(target[property])
      ? deepAssign(target[property], sourceProperty)
      : isObject(sourceProperty)
        ? deepAssign({}, sourceProperty)
        : isArray(sourceProperty)
          ? (mergeArray(target[property] as unknown as [], sourceProperty as unknown as []) as unknown as T[keyof T])
          : sourceProperty;
}
function mergeArray<T>(target: T, source: T) {
  target ??= [] as unknown as T;
  for (let i = 0; i < (source as unknown as []).length; i++) {
    copyProperty(source, target, i as keyof T);
  }
  return target as unknown as T[keyof T];
}

function removeNodeProperty<T>(source: T, target: T, property: Extract<keyof T, string>) {
  if (Object.getOwnPropertyDescriptor(target, property)?.get) {
    delete target[property];
    return;
  }

  const s = source[property];
  const t = target[property];

  if (s === t || (Array.isArray(t) && t.length === 0)) {
    delete target[property];
  } else if (isObject(s) && isObject(t)) {
    Object.keys(s).forEach((x) => removeNodeProperty(s, t, x as Extract<keyof typeof s, string>));
    if (Object.keys(t).length === 0) {
      delete target[property];
    }
  }
}
export function deepAssign<T, S>(target: T, ...sources: S[]) {
  sources.forEach((source: any) => Object.keys(source).forEach((x) => copyProperty(source, target, x)));
  return target as S & T;
}

export function deepAssignSingleNode<T extends object>(target: T, ...sources: T[]) {
  sources.forEach((source: T) =>
    Object.keys(source)
      .filter((x) => x !== 'children')
      .forEach((x) => copyProperty(source, target, x as keyof T)),
  );
  return target;
}

export function deepRemoveSingleNode<T extends object>(target: T, ...sources: (T | undefined)[]) {
  const source = Object.assign({} as T, ...sources);

  Object.keys(source)
    .filter((x) => x !== 'children' && x !== 'type')
    .forEach((x) => removeNodeProperty(source, target, x as Extract<keyof T, string>));
  const sourceKeys = Object.keys(source);
  Object.keys(target)
    .filter((x) => !sourceKeys.includes(x) && !x.startsWith('$') && x !== 'children')
    .forEach((x) => delete target[x as keyof T]);

  // values of the category are set by updateCategories() // no need to save this value
  if (isType(target, 'categories') && target.categories) {
    const categories = target.categories;
    for (const category of categories) {
      delete category.isEnabled;
      delete category.isVisible;
      if (!category.isSignalMailTrigger) {
        delete category.isSignalMailTrigger;
      }
      if (!category.isConsent) {
        delete category.isConsent;
      }
    }
  }

  // remove obsolete expressions
  Object.entries(target)
    .filter(([key, value]) => !value && key.startsWith('$'))
    .forEach(([key]) => delete target[key as keyof typeof target]);
  return target;
}
