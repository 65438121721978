<div class="d-flex align-items-start">
  <mat-icon class="me-2 mt-2">
    <lsf-themes-svg-preview [theme]="themeEditorService.theme"></lsf-themes-svg-preview>
  </mat-icon>

  <mat-form-field appearance="fill" class="flex-grow-1">
    <mat-label>Theme name</mat-label>
    <input
      matInput
      name="themeName"
      lsuDirectivesFocus
      [(ngModel)]="themeEditorService.theme.name"
      required
      #themeName="ngModel"
      [pattern]="themeNameRegexp"
    />
    <mat-error *ngIf="themeName.hasError('required')">The field 'Theme name' is required.</mat-error>
    <mat-error *ngIf="themeName.hasError('pattern')"
      >The theme name field does not comply with alphanumeric characters, spaces and or dashes (-).</mat-error
    >
    <mat-hint>Alphanumeric characters, spaces and dashes (-) are allowed.</mat-hint>
  </mat-form-field>
</div>
<mat-slide-toggle
  *ngIf="!editProjectTheme"
  [(ngModel)]="themeEditorService.theme.isDefault"
  name="isDefault"
  [disabled]="storedTheme ? storedTheme.isDefault : !hasRoleWithinAdminPolicy"
  >Set this new theme as the default theme.</mat-slide-toggle
>
