/*************************************************************************************************
 * WARNING: code used in generated typescript. Do not change without understanding consequence
 *************************************************************************************************/

import { isType } from './is-type';

export class defaultValues {
  static languageSet = { locale: 'nl-NL', description: 'Nederlands' };
  static englishLanguageSet = { locale: 'en-GB', description: 'English' };

  static locale = 'localeId';
  static _localeId = '_localeId';
  static availableSampleLang = 'availableSampleLang';
  static validationCode = 'validationCode';
  static validationCodeSeparator = '_';

  public static getDefaultTranslationsFromWindow(locale: string): Record<string, string> {
    const defaults = isType(window, 'defaultTranslations') ? (window.defaultTranslations as Record<string, Record<string, string>>) : {};
    return locale in defaults ? (defaults[locale] ?? {}) : (defaults[this.englishLanguageSet.locale] ?? {});
  }
}
