<div class="d-flex justify-content-between mb-2">
  <div>
    <div class="text-center mat-subtitle-2">Text colour on primary</div>
    <lsf-themes-editor-color-picker [(color)]="textColors.primaryColor"></lsf-themes-editor-color-picker>
  </div>
  <div>
    <div class="text-center mat-subtitle-2">Text colour on accent</div>
    <lsf-themes-editor-color-picker [(color)]="textColors.accentColor"></lsf-themes-editor-color-picker>
  </div>
  <div>
    <div class="text-center mat-subtitle-2">Background colour</div>
    <lsf-themes-editor-color-picker [(color)]="backgroundColors.lightColor"></lsf-themes-editor-color-picker>
  </div>
</div>
<lsf-themes-editor-accessibility-preview></lsf-themes-editor-accessibility-preview>
