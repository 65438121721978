import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { ucFirst } from '@shared/util/code';

@Component({
  selector: 'lsu-dialog-maximize',
  templateUrl: './maximize.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, MatButtonModule, MatTooltipModule, MatIconModule],
})
export class MaximizeComponent {
  isFullScreen = false;
  readonly ucfirst = ucFirst;
  origWidth!: string;
  origHeight!: string;
  origContentHeight!: string;
  constructor(private dialogRef: MatDialogRef<unknown>) {}

  onUpdateSize() {
    !this.isFullScreen ? this.setFullScreen() : this.revertScreenSize();
    this.toggleFullScreen();
  }
  updateContentHeight(contentEl: HTMLElement, height: string) {
    // important is needed when de height is set with class h-100
    contentEl.style.cssText = `height: ${height} !important`;
  }

  calcContentSize(contentEl: HTMLElement) {
    const dialogRef = this.dialogRef;
    const elementRef = document.getElementById(dialogRef.id)!;
    const height = elementRef.parentElement!.clientHeight;
    const contentHeight = contentEl.clientHeight;
    const remainingHeight = height - contentHeight; //the remaining height is the header and buttons
    return `calc(98vh - ${remainingHeight}px)`; // 98vh is the height of the dialog,
  }

  setFullScreen() {
    const dialogRef = this.dialogRef;
    const elementRef = document.getElementById(dialogRef.id)!;
    const contentEl = this.contentElement(elementRef);
    this.origContentHeight = contentEl.style.height; // Normally the content height is not set, but for the monaco editor it must be set.
    this.updateContentHeight(contentEl, this.calcContentSize(contentEl));

    // The size is/(will be) set on the parent element.
    this.origWidth = elementRef.parentElement!.style.width;
    this.origHeight = elementRef.parentElement!.style.height;
    dialogRef.addPanelClass('maximize');
    dialogRef.updateSize('98vw', '98vh');
  }

  revertScreenSize() {
    const dialogRef = this.dialogRef;
    const elementRef = document.getElementById(dialogRef.id)!;
    const contentEl = this.contentElement(elementRef);
    this.updateContentHeight(contentEl, this.origContentHeight);
    dialogRef.removePanelClass('maximize');
    dialogRef.updateSize(this.origWidth, this.origHeight);
  }
  private toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }
  private contentElement = (elementRef: HTMLElement): HTMLElement => elementRef.querySelector('mat-dialog-content')!;
}
