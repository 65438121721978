import { AppInsightsService } from '@shared/util/infrastructure';
import DOMPurify, { Config } from 'dompurify';

export const responseConfig: Config = { ADD_ATTR: ['target', ''], ADD_TAGS: ['mat-icon'] };

export function domPurifySanitize(value: string, log: boolean): string {
  if (!value) {
    return '';
  }

  const config = { ...responseConfig, ...{ RETURN_DOM_FRAGMENT: false, RETURN_DOM: false } }; // make sure we always return a string and not DOM elements

  const safeHtml = DOMPurify.sanitize(value, config) as string;
  if (log) {
    logUnsafeHtml(safeHtml, value);
  }

  return safeHtml;
}

export function logUnsafeHtml(safeHtml: string, value: string) {
  const parsedHTMLFromValue = new DOMParser().parseFromString(value, 'text/html');
  const transformedValue = parsedHTMLFromValue.body.innerHTML;
  if (safeHtml !== transformedValue) {
    const safeHtmlTags = getTagsOnly(safeHtml);
    const valueTags = getTagsOnly(transformedValue);
    if (safeHtmlTags !== valueTags && safeHtmlTags?.length !== valueTags?.length) {
      AppInsightsService.trackTraceError('found unsafe html:', { orgValue: value, unsafeValue: valueTags, safeValue: safeHtmlTags });
    }
  }
}

function getTagsOnly(html: string | null) {
  return html?.match(/<[^>]*>?/gm)?.join('');
}
