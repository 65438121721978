import { IColumn } from '../Interfaces/IColumn';
import { StyleWidthTypes } from './column-width';

export function createColumnStyleRules<T1, T2>(columns: IColumn<T1, T2>[], id: string): string[] {
  const result: string[] = [];
  for (const col of columns) {
    const colName = getColumnName<T1, T2>(col);
    const widths = getWidths(col);

    const initProp = `width: ${widths[0]}px;`;
    const minProp = `min-width: ${widths[1]}px;`;
    const maxProp = `max-width: ${widths[2]}px;`;
    const alignRight = col.arrowPosition === 'before' ? 'justify-content: flex-end; text-align: right;' : '';
    const alignHeaderRight = col.arrowPosition === 'before' ? 'text-align: right;' : '';
    const inlineEdit = col.inlineEdit ? 'cursor: pointer;' : '';
    const rule = `
    .${id} .mat-mdc-header-cell.mat-column-${colName},
    .${id} .mat-mdc-footer-cell.mat-column-${colName},
    .${id} .mat-mdc-cell.mat-column-${colName}{
      ${initProp}
      ${minProp}
      ${maxProp}
      ${alignRight}
      ${inlineEdit}
    }`;

    result.push(rule);
    if (col.arrowPosition) {
      result.push(`.${id} .mat-mdc-header-cell.mat-column-${colName} .mat-sort-header-content {${alignHeaderRight}}`);
    }
  }

  return result;
}

// Converts the name of a column to a string with special characters escaped.
function getColumnName<T1, T2>(col: IColumn<T1, T2>) {
  return col.name.replace(/[^a-zA-Z_0-9]/g, (c) => '\\u' + c.charCodeAt(0).toString(16).padStart(4, '0'));
}

function getWidths<TRowData, TRowVirtual>(col: IColumn<TRowData, TRowVirtual>): number[] {
  return typeof col.widths === 'string' ? StyleWidthTypes[col.widths] : (col.widths ?? []);
}
