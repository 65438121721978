import { DestroyRef, Directive, EventEmitter, Input, OnInit, Signal, Type, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenuTrigger } from '@angular/material/menu';
import { ColumnType } from '../Interfaces/IColumnBase';
import { InlineEditButtonComponent } from '../popover-edit/inline-edit-button.component';
import { IDynamicTableEvents } from '../table/table-events.component';
import { IColumnDefinition } from './column-extender';
import { MatCellCheckboxComponent } from './mat-cell-checkbox.component';
import { MatCellDefaultComponent } from './mat-cell-default.component';
import { MatCellIconComponent } from './mat-cell-icon.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matCellComponent]',
  standalone: true,
})
export class MatCellHostDirective<T1, T2> implements OnInit {
  @Input('matCellComponent') host!: IDynamicTableEvents<T1, T2>;
  @Input() row!: T1;
  @Input() index!: number;
  @Input() column!: IColumnDefinition<T1, T2>;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private destroyRef: DestroyRef,
  ) {}
  ngOnInit(): void {
    const column = this.column;
    if (column.type === 'menu') {
      const compType = column._valueFunc(this.row) as unknown as Type<IMatCellMenuComponent<T1>>;
      this.createMenuComponent(compType);
    } else {
      const compType = this.getComponentName(column.inlineEdit ? 'inlineEdit' : column.type);
      this.createCellComponent(compType);
    }
  }
  getComponentName(type?: ColumnType | 'inlineEdit'): Type<IMatCellHostComponent<T1, T2>> {
    return type === 'checkbox'
      ? MatCellCheckboxComponent<T1, T2>
      : type === 'icon'
        ? MatCellIconComponent<T1, T2>
        : type === 'inlineEdit'
          ? InlineEditButtonComponent<T1, T2>
          : MatCellDefaultComponent<T1, T2>;
  }
  createMenuComponent(component: Type<IMatCellMenuComponent<T1>>) {
    this.viewContainerRef.clear();
    const instance = this.viewContainerRef.createComponent(component).instance;
    instance.row = this.row;
    instance.menuItemClick
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((item) => this.host.menuItemClick.emit({ item, row: this.row, column: this.column, instance }));
  }
  createCellComponent<T extends IMatCellHostComponent<T1, T2>>(component: Type<T>) {
    this.viewContainerRef.clear();
    const instance = this.viewContainerRef.createComponent(component).instance;
    instance.host = this.host;
    instance.row = this.row;
    instance.column = this.column;
    instance.index = this.index;
  }
}
export declare interface IMatCellHostComponent<TRowData, TRowVirtual> {
  host: IDynamicTableEvents<TRowData, TRowVirtual>;
  row: TRowData;
  index: number;
  column: IColumnDefinition<TRowData, TRowVirtual>;
}

export declare interface IMatCellMenuComponent<T> {
  row: T;
  menuItemClick: EventEmitter<string>;
  matMenuTriggerFor?: Signal<MatMenuTrigger>;
}
