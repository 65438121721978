<lsuCloseOnEscape></lsuCloseOnEscape>
<h1 mat-dialog-title>Theme editor</h1>

<mat-dialog-content>
  <lsf-themes-editor-wizard [editProjectTheme]="editProjectTheme" [creating]="creating" [theme]="currentTheme"></lsf-themes-editor-wizard>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="button" mat-stroked-button [mat-dialog-close]="false">Close</button>
  <div class="ms-auto">
    <ng-container *ngTemplateOutlet="wizardActionButtons"></ng-container>
  </div>
</mat-dialog-actions>
