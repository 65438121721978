import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SettingClient, ThemeClient, UpdateThemeModel } from '@shared/data-access/common';
import { getItemProjectSpecific, getProjectGuid, safeLocalStorage, setItemProjectSpecific } from '@shared/util/code';
import { firstValueFrom, Subject } from 'rxjs';
import { ThemeModel } from '../shared/models/theme.model';
import { InitialThemingService } from './initial-theming.service';
import { ThemeColorService } from './theme-color.service';
import { TypographyService } from './typography.service';

/**
 * TODO/Idea: Migrate ThemeService to an NgRx DataStore.
 *
 * @example
 * `store.setActiveTheme(theme)`
 * NgRx Action, calls the api and saves the result to the datastore through an NgRx Reducer.
 * Optional data manipulation can be done by calling an NgRx Effect.
 *
 * `store.getActiveTheme(project).subscribe()`
 * NgRx Selector, Always emits latest current theme.
 *
 * *All @Inputs, @Outputs and manual change detection can now be removed.*
 */
@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  static readonly theme_variable_prefix = '--theme';
  themes: ThemeModel[];
  defaultTheme: ThemeModel;
  themeUpdate$ = new Subject<ThemeModel | void>();

  set activeTheme(theme: ThemeModel) {
    const projectGuid = getProjectGuid(document.location.pathname);
    projectGuid
      ? setItemProjectSpecific(InitialThemingService.localStorageActiveThemeId, `${theme.id}`)
      : safeLocalStorage.setItem(InitialThemingService.localStorageActiveThemeId, `${theme.id}`);
    this._activeTheme = theme;
    this.initTheme(theme);
  }

  get activeTheme(): ThemeModel {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this._activeTheme) {
      return this._activeTheme;
    }
    const projectGuid = getProjectGuid(document.location.pathname);
    const id = projectGuid
      ? getItemProjectSpecific(InitialThemingService.localStorageActiveThemeId)
      : safeLocalStorage.getItem(InitialThemingService.localStorageActiveThemeId);
    const selectedTheme = this.themes.find((theme) => theme.id === id!);
    return !selectedTheme ? this.defaultTheme : selectedTheme;
  }

  private _activeTheme: ThemeModel;

  constructor(
    private settingClient: SettingClient,
    private initialThemingService: InitialThemingService,
    private themeColorService: ThemeColorService,
    private typographyService: TypographyService,
    private themeClient: ThemeClient,
  ) {
    this.themes = this.initialThemingService.themes;
    this.defaultTheme = this.tryGetDefaultTheme()!;
    this._activeTheme = this.initialThemingService.activeTheme;
  }

  /**
   * Change a camelCase variable to a kebab case
   * e.g: primaryColor -> primary-color
   * @param key
   */
  static convertCamelCaseToKebabCase(key: string): string {
    return key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
  }

  static prependVariableName(key: string): string {
    return `${ThemingService.theme_variable_prefix}-${key}`;
  }

  async deleteTheme(index: number) {
    this.themes.splice(index, 1);
    const theme = this.tryGetDefaultTheme();
    if (!theme) {
      this.setDefaultTheme(this.themes[0]);
    }
    this.updateThemes(JSON.stringify(this.themes));
  }

  async addTheme(theme: ThemeModel) {
    this.themes.push(theme);
    if (theme.isDefault) {
      this.setDefaultTheme(theme);
    }
    this.updateThemes(JSON.stringify(this.themes));
  }

  updateTheme(theme: ThemeModel) {
    const idx = this.themes.findIndex((t) => t.id === theme.id);
    if (idx >= 0) {
      this.themes[idx] = theme;
      if (theme.isDefault) {
        this.setDefaultTheme(this.themes[idx]);
      }
      this.updateThemes(JSON.stringify(this.themes));
    } else {
      new Error(`Theming.service, theme '${theme.name}' was not found.`);
    }
  }

  tryGetTheme(id: string) {
    return this.themes.find((t) => t.id === id);
  }

  tryGetDefaultTheme() {
    return this.themes.find((t) => t.isDefault);
  }

  updateProjectTheme(theme: ThemeModel) {
    this.activeTheme = theme;
    this.themeUpdate$.next(theme);
  }

  async updateAndSaveProjectTheme(themeModel: UpdateThemeModel) {
    await firstValueFrom(this.themeClient.updateProjectTheme(themeModel));
  }

  async updateThemes(themes: string) {
    await firstValueFrom(this.settingClient.setSetting('TenantTheme', themes));
    this.themeUpdate$.next();
  }

  private initTheme(theme: ThemeModel) {
    this.themeColorService.updateStyle(theme);
    this.typographyService.setupTypographyLevel(theme);
  }

  private setDefaultTheme(theme: ThemeModel) {
    const serviceTheme = this.themes.find((t) => t.id === theme.id);
    if (serviceTheme) {
      this.themes.forEach((t) => (t.isDefault = false));
      serviceTheme.isDefault = true;
      this.defaultTheme = serviceTheme;
    } else {
      new Error(`Theming service: theme '${theme.name}' was not found.`);
    }
  }
}
