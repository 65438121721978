<div
  class="color-picker d-flex justify-content-center align-items-center cursor-pointer"
  ngx-colors-trigger
  [palette]="presets()"
  [(ngModel)]="color"
  [style.color]="color()! | textColor"
  [style.background]="color()"
  [formats]="['hex']"
  colorPickerControls="no-alpha"
  (open)="onColorPickerOpen()"
>
  {{ color() }}
</div>
