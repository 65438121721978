export function getValueText<T extends number | string>(list: Record<T, string>): { value: T; text: string }[] {
  return Object.keys(list).some((x) => Number.isNaN(Number(x)))
    ? Object.entries(list).map((x) => ({ value: x[0] as T, text: x[1] as string }))
    : Object.entries(list).map((x) => ({ value: +x[0] as T, text: x[1] as string }));
}
// export function getValueText<T extends number | string>(list: Record<T, unknown>): { value: T; text: string }[] {
//   return areKeyAllNumbers<T>(list) ? getNumberText<T>(list) : getStringText<T>(list);
// }

// function checkNumberEnum<T extends number | string>(list: Record<T, unknown>) {
//   const l = Object.keys(list).length;
//   const l2 = Object.keys(list).filter((key) => Number.isNaN(Number(key))).length;
//   return l / 2 === l2;
// }

// function areKeyAllNumbers<T extends number | string>(list: Record<T, unknown>) {
//   return !Object.keys(list).some((x) => Number.isNaN(Number(x)));
// }

// function getStringText<T extends number | string>(list: Record<T, unknown>): { value: T; text: string }[] {
//   if (checkNumberEnum(list)) {
//     return Object.entries(list)
//       .filter((x) => isNaN(+(x[1] as number)))
//       .map((x) => ({ value: +x[0] as T, text: x[1] as string }));
//   }
//   return Object.entries(list).map((x) => ({ value: +x[0] as T, text: x[1] as string }));
// }

// function getNumberText<T extends number | string>(list: Record<T, unknown>): { value: T; text: string }[] {
//   return Object.entries(list).map((x) => ({ value: x[0] as T, text: x[1] as string }));
// }
