export const isType = <T extends object>(obj: unknown, property: keyof T): obj is T => property in <T>obj;

type Obj = Record<string | number, unknown>;
export const getInitializeProperty = <T extends Obj, R = T[keyof T]>(obj: T, propName: keyof T | number, defaultValue: T[keyof T]) => {
  obj[propName] ??= defaultValue;
  return obj[propName] as R;
};
export const ensureInitializeProperty = <T extends Obj>(obj: T, propName: keyof T | number, defaultValue: T[keyof T]) =>
  (obj[propName] ??= defaultValue);

export const isNodeWithChildren = <T>(node: T): node is NodeWithChildren<NonNullable<T>> => node && typeof node === 'object' && 'children' in node;
export const hasCategories = <T, R extends ClosedQuestion<T>>(node: T): node is R => node && typeof node === 'object' && 'categories' in node;

export type NodeWithChildren<T> = T & { children: T[] };
export type ClosedQuestion<T> = T & { categories: GenericCategory[] };
export type GenericNode = { children?: GenericNode[]; name: string; categories?: GenericCategory[] };
export type GenericCategory = { otherQuestion?: GenericNode };
